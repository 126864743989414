import {createContext, useContext} from "react";

export const AuthenticationInformation = createContext({
    user: undefined,
    isLoading: false,
    setUser: () => {
    },
});

export const GetAuthenticationInformation = () => useContext(AuthenticationInformation);
