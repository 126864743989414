import CrossBtn from '../../images/icons/CrossBtncross.png';
import {
    GetAuthenticationInformation
} from "../../components/AuthenticationInforamtionAndManager/AuthenticationInformation";
import {API, BASE_URL, BEARER} from "../../API";
import React, {useEffect, useState} from "react";
import {getToken} from "../../components/AuthenticationInforamtionAndManager/AuthenticationTokens";
import DefaultProfilePic from "../../images/miscellaneous/DefaultProfilePhoto.jpg";

function EditProfile({onClose, onEditClick}) {
    const { user, setUser } = GetAuthenticationInformation();
    const [displayName, setDisplayName] = useState(user?.username);
    const [email, setEmail] = useState(user?.email);
    const [institution, setInstitution] = useState("");
    const [studentStatus, setStudentStatus] = useState("");
    const [universities, setUniversities] = useState([]);
    const [filteredUniversities, setFilteredUniversities] = useState([]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    const [profilePhoto, setProfilePhoto] = useState("");
    const [photoFile, setPhotoFile] = useState(null);

    useEffect(() => {
        if (user) {
            setDisplayName(user.username);
            setEmail(user.email);
            setInstitution(user.university?.universityName || "");
            setStudentStatus(user.studentStatus || "");
            setLoading(false);
            setProfilePhoto(user.avatar?.formats?.thumbnail?.url || "");
        }
    }, [user]);

    useEffect(() => {
        const fetchUniversities = () => {
            fetch(`${API}/university-pages?fields[0]=universityName`)
                .then(response => response.json())
                .then(data => {
                    setUniversities(data.data);
                })
                .catch(error => {
                    console.error('Error fetching universities:', error);
                });
        };

        fetchUniversities();
    }, []);

    const handleUniversityInputChange = (e) => {
        const input = e.target.value.toLowerCase();
        const filtered = universities.filter(u => u.attributes.universityName.toLowerCase().includes(input));
        setFilteredUniversities(filtered);
        setInstitution(input);
    };

    const handleUniversityItemClick = (universityName) => {
        setInstitution(universityName);
        setFilteredUniversities([]);
    };

    const handleUpdateProfile = async () => {
        const updatedUser = {
            ...user,
            username: displayName,
            email: email,
            university: {
                ...user?.university,
                universityName: institution
            },
            studentStatus: studentStatus
        };

        const formData = new FormData();
        formData.append("files", photoFile);

        const valuesChanged = (
            updatedUser.username !== user.username ||
            updatedUser.email !== user.email ||
            updatedUser.university?.universityName !== user.university?.universityName ||
            updatedUser.studentStatus !== user.studentStatus
        );

        if (photoFile && photoFile.name !== profilePhoto.slice(profilePhoto.lastIndexOf("/") + 1)) {
            try {
                const response = await fetch(`${API}/upload/files`);
                const responseData = await response.json();

                const id = responseData.filter(u => u.formats.thumbnail.url.toLowerCase().includes(profilePhoto))[0].id

                try {
                    const token = getToken();

                    setLoading(true);

                    fetch(`${API}/upload?id=${id}`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `${BEARER} ${token}`
                        },
                        body: formData
                    })
                        .then(response => response.json())
                        .then(async data => {
                            updatedUser.avatar = data;

                            const updateResponse = await fetch(`${API}/users/${user.id}`, {
                                method: 'PUT',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `${BEARER} ${token}`
                                },
                                body: JSON.stringify(updatedUser)
                            });

                            const updatedData = await updateResponse.json();
                            setUser(updatedData);
                            setProfilePhoto(data.formats.thumbnail.url);
                            setError("Profile Updated!");
                        })
                        .catch(error => {
                            console.error('Error uploading file:', error);
                            setError(error?.message ?? "Error!");
                        })
                        .finally(() => {
                            setLoading(false);
                        });

                } catch (error) {
                    console.error(JSON.stringify(error));
                }
            } catch (error) {
                console.error(JSON.stringify(error));
            }

        } else if (valuesChanged) {
            setUser(updatedUser);
            const token = getToken();

            setLoading(true);

            fetch(`${API}/users/${user.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${BEARER} ${token}`
                },
                body: JSON.stringify(updatedUser),
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Success:', data);
                    setError("Profile Updated!");
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setError(error?.message ?? "Error!");
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setError("No values changed!");
        }

    };

    return (
        <div className="loginContainer">

            <div className='editProfilePage'>
                {user && (
                    <div className='editProfileBubble'>

                        <div className="statsCrossDiv">
                            <button className='editProfileButton' onClick={onEditClick}>Edit Profile</button>
                            <img src={CrossBtn} className="statsCrossBtn" alt="Cancel button" onClick={onClose}/>
                        </div>

                        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <img src={user.avatar ? `${BASE_URL}${profilePhoto}` : DefaultProfilePic} className='profilePhoto'
                             alt='Profile photo'/>
                        <br/>
                        <input type="file" onChange={(e) => setPhotoFile(e.target.files[0])} accept="image/*"/>

                        <div className='inputs'>
                            <div className='inputQuadrant'>
                                <div className='displayNameTitle'>Display name</div>
                                <input className='displayNameInput' placeholder={user?.username}
                                       onChange={(e) => setDisplayName(e.target.value)} type='text'></input>
                            </div>

                            <div className='inputQuadrant'>
                                <div className='studentStatusTitle'>Student status</div>
                                <select className='studentStatusInput' defaultValue={user?.studentStatus}
                                        onChange={(e) => setStudentStatus(e.target.value)}>
                                    <option value='Undergraduate'>Undergraduate</option>
                                    <option value='Postgraduate'>Postgraduate</option>
                                    <option value='Looking for University'>Looking for a University</option>
                                </select>
                            </div>

                            <div className='inputQuadrant'>
                                <div className='emailTitle'>Email</div>
                                <input className='emailInput' placeholder={user?.email}
                                       onChange={(e) => setEmail(e.target.value)} type='email'></input>
                            </div>

                            <div className='inputQuadrant'>
                                <div className='universityTitle'>University</div>
                                <input className='universityInput' value={institution}
                                       onChange={handleUniversityInputChange} type='text'/>
                                {filteredUniversities.length > 0 && (
                                    <ul className="universityList">
                                        {filteredUniversities.map((university, index) => (
                                            <h2 key={index}
                                                onClick={() => handleUniversityItemClick(university.attributes.universityName)}>
                                                {university.attributes.universityName}
                                            </h2>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>

                        {error && <h4 className="error">{error}</h4>}

                        <button className="submitButtonLogout" onClick={handleUpdateProfile}>
                            <p>{loading ? "Updating Profile..." : "Update Profile"}</p>
                        </button>

                    </div>
                )}
            </div>
        </div>
    );
}

export default EditProfile