import React, { useState } from 'react';
import Heart from '../../images/icons/Heart.png';
import HeartPink from '../../images/icons/HeartPink.png';
import Comment from '../../images/icons/BoldComment.png';
import DefaultProfilePic from "../../images/miscellaneous/DefaultProfilePhoto.jpg";
import {BASE_URL} from "../../API";
import StarIcon from "../../images/icons/StarYellow.png";

function ReviewCard(args) {
    // Initialize likeCount as a number using the unary plus operator to convert it from string if necessary
    const [likeColor, setLikeColor] = useState('gray');
    const [likeIcon, setLikeIcon] = useState(Heart);
    const [likeCount, setLikeCount] = useState(args.like ? +args.like : 0);

    // Handler for like button click
    const handleLikeClick = () => {
        const isRed = likeColor === '#ff1564';
        setLikeColor(isRed ? 'gray' : '#ff1564');  // Toggle text color
        setLikeIcon(isRed ? Heart : HeartPink);  // Toggle icon
        setLikeCount(prevCount => isRed ? prevCount - 1 : prevCount + 1);  // Increment or decrement the like count using a function to ensure the latest state
    };

    return (
        <div className="mainCard">
            <div className="topicDiv">
                <div className="profileIcon">
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    <img className="reviewProfile"
                         src={args.avatar == null ? DefaultProfilePic : `${BASE_URL}${args.avatar.url}`}/>
                </div>
                <div className="infoDiv">
                    <h1>{args.name ? args.name : "Anonymous"}</h1>
                    <div className="reviewRatingDiv">
                        <p className="reviewRating" >{args.rating ? args.rating : ""}</p>
                        {args.rating ? <img src={StarIcon} className="reviewImg" alt="Star icon"/>: ""}
                        <h2>{args.posttime ? args.posttime : "Just now"}</h2>
                     </div>
                </div>

            </div>

            <div className="sepLine"/>

            <div>
            <p className="desc">{args.desc}</p>
            </div>

            <div className="iconMainDiv">
                <button className="cardButtonStyling" onClick={handleLikeClick}>
                    <div className="iconDiv">
                        <img className="icons" src={likeIcon} alt="Like"/>
                        <p style={{ color: likeColor }}>{likeCount}</p>
                    </div>
                </button>

                <button className="cardButtonStyling">
                    <div className="iconDiv">
                        <img className="icons" src={Comment} alt="Comment"/>
                        <p>{args.comment ? args.comment : 0}</p>
                    </div>
                </button>
            </div>
        </div>
    );
}

export default ReviewCard;
