import React, {useEffect, useState} from "react";
import {AuthenticationInformation} from "./AuthenticationInformation";
import {getToken} from "./AuthenticationTokens"
import {API, BEARER} from "../../API";

const AuthenticationManager = ({children}) => {
    const [getUserData, setUserData] = useState();
    const [getIsLoading, setIsLoading] = useState(false);

    const authToken = getToken();

    const fetchLoggedInUser = async (token) => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API}/users/me?fields[0]=username&fields[1]=email&fields[2]=studentStatus&populate[university][fields][0]=universityName&populate[avatar][fields][0]=formats&populate[avatar][fields][1]=url&populate[comments][fields][0]=id&populate[reviews][fields][0]=&populate[blogs][fields][0]=&populate[qnas][fields][0]=`, {
                headers: {Authorization: `${BEARER} ${token}`},
            });
            const data = await response.json();

            setUserData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleUser = (user) => {
        setUserData(user);
    };

    useEffect(() => {
        if (authToken) {
            fetchLoggedInUser(authToken);
        }
    }, [authToken]);

    return (
        <AuthenticationInformation.Provider value={{user: getUserData, setUser: handleUser, getIsLoading}}>
            {children}
        </AuthenticationInformation.Provider>
    );
};

export default AuthenticationManager;
