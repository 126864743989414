import StarIcon from '../../../images/icons/StarYellow.png';

import InteractionArea from '../../../components/InteractionArea/InteractionArea';

import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {BASE_URL} from "../../../API";

// main export
function UniReview() {
    const {idUniversity} = useParams();
    const [university, setUniversity] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${BASE_URL}/api/university-pages/${idUniversity}
                    ?populate[universityLogo][fields][0]=name
                    &populate[universityLogo][fields][1]=alternativeText
                    &populate[universityLogo][fields][2]=formats
                    &populate[universityHeaderImage][fields][0]=name
                    &populate[universityHeaderImage][fields][1]=alternativeText
                    &populate[universityHeaderImage][fields][2]=formats
                    &populate[program_pages][fields][0]=programName
                    &populate[program_pages][fields][1]=programRating
                    &populate[subject_pages][fields][0]=subjectName
                    &populate[subject_pages][fields][1]=subjectRating
                    `);
                const responseData = await response.json();

                setUniversity(responseData.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [idUniversity]);

    if (!university) {
        return <div>Loading...</div>;
    }

    return (
        <div style={{margin: '0', width: '100vw', height: '100vh', position: 'relative'}}>
            <div style={{position: 'relative'}}>
                <UniBanner universityData={university.attributes.universityHeaderImage.data.attributes}/>
                <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '15%'}}>
                    <UniTitleAndImg universityHeader={university.attributes}/>
                </div>
            </div>
            <div className="programAndSubjectMainHeight" style={{marginTop: '-3%', position: 'relative'}}>
                <ProgramAndSubject universityHeader={university}/>
            </div>
            <InteractionArea interactionName={`university-pages/${idUniversity}`}/>
        </div>
    );
}

function UniTitleAndImg({universityHeader}) {
    return (
        <div className="titleAndTextContainer">
            <div className="titleAndTextSub">
                <h1 className="universityHeadingText">{universityHeader.universityName}</h1>
                <p className="suburbText">{universityHeader.universityLocation}</p>
            </div>


            <img src={BASE_URL + universityHeader.universityLogo.data.attributes.formats.thumbnail.url}
                 className="universityLogo" alt={universityHeader.universityLogo.data.attributes.alternativeText}/>
        </div>
    );
}

function ProgramAndSubject({universityHeader}) {
    const sortedPrograms = universityHeader.attributes.program_pages.data.sort((a, b) => {
        const ratingA = a.attributes.programRating;
        const ratingB = b.attributes.programRating;

        if (ratingA === null) return 1;
        if (ratingB === null) return -1;

        return ratingB - ratingA;
    });

    const sortedSubjects = universityHeader.attributes.subject_pages.data.sort((a, b) => {
        const ratingA = a.attributes.subjectRating;
        const ratingB = b.attributes.subjectRating;

        if (ratingA === null) return 1;
        if (ratingB === null) return -1;

        return ratingB - ratingA;
    });

    return (
        <div className="programAndSubjectHeight">
            {universityHeader.attributes.program_pages.data.length > 0 && (
                <>
                    <h3 className="sectionTitle">Programs</h3>
                    <div className="programAndSubjectContainer">
                        {sortedPrograms.slice(0, 3).map((university) => (
                            <Link className="linkStyle"
                                  to={`/universities/${universityHeader.id}/program/${university.id}`}>
                                <ShadowBox
                                    name={university.attributes.programName}
                                    rating={university.attributes.programRating !== null
                                        ? university.attributes.programRating
                                        : "-"}
                                />
                            </Link>
                        ))}
                    </div>
                </>
            )}

            {universityHeader.attributes.subject_pages.data.length > 0 && (
                <>
                    <h3 className="sectionTitle pushPadding">Subjects</h3>
                    <div className="programAndSubjectContainer">
                        {sortedSubjects.slice(0, 3).map((university) => (
                            <Link className="linkStyle"
                                  to={`/universities/${universityHeader.id}/subject/${university.id}`}>
                                <ShadowBox
                                    name={university.attributes.subjectName}
                                    rating={university.attributes.subjectRating !== null
                                        ? university.attributes.subjectRating
                                        : "-"}
                                />
                            </Link>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

function ShadowBox(args) {
    const {name, rating} = args;
    return (
        <div className="programAndSubjectButtonContainer">
            <p className="programAndSubjectText">{name}</p>
            <div className="programAndSubjectRatingDiv">
                <p className="programAndSubjectRating">{rating}</p>
                <img src={StarIcon} className="programAndSubjectImg" alt="Star icon"/>
            </div>
        </div>
    )
}

function UniBanner({universityData}) {
    return (
        <div style={{height: '60%', position: 'relative'}}>
            <div className="topDownGradientPosition topDownGradient"></div>
            <img src={BASE_URL + universityData.formats.large.url}
                 className="backgroundImageStyle" alt={universityData.alternativeText}/>
            <div className="bottomUpGradient"
                 style={{position: 'absolute', bottom: 0, left: 0, width: '100%', height: '20%'}}>
            </div>
        </div>
    );
}

export default UniReview;
