import {GetAuthenticationInformation} from "../AuthenticationInforamtionAndManager/AuthenticationInformation";
import React, {useState} from "react";
import {getToken} from "../AuthenticationInforamtionAndManager/AuthenticationTokens";
import {BASE_URL, BEARER} from "../../API";
import CrossBtn from "../../images/icons/CrossBtncross.png";
import SCLogoWhite from "../../images/logos/SCLogoWhiteBG.png";
import Next from "../../images/logos/nextIcon.png";
import Login from "../../user/Login/Login";

function AddPostPopup({onClose, interactionName, fetchReviewData, fetchBlogData, fetchQnAData}) {
    const {user} = GetAuthenticationInformation();
    const [isSending, setIsSending] = useState(false);
    const [error, setError] = useState("");
    const isLoggedIn = !!user;

    const toggleLoginVisibility = () => setShowLogin(!showLogin);

    const [showLogin, setShowLogin] = useState(true);

    const [postTypeSelect, setPostTypeSelect] = useState("");


    const handleAddPost = async (values) => {
        setIsSending(true);
        try {
            const token = getToken();

            const response = await fetch(`${BASE_URL}/api/${values.postTypeSelect}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${BEARER} ${token}`,
                },

                body: JSON.stringify({data: values.newPostData}),
            });

            if (response.ok) {
                console.log('Post created successfully');
            } else {
                console.error('Error creating post:', response.statusText);
            }
        } catch (error) {
            console.error('Error creating post:', error);
            setError(error?.message ?? "Error!");
        } finally {
            setIsSending(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const postTypeSelect = e.target.elements.postType.value;

        let newPostData;
        const idInteractionName = interactionName.split("/")[1];

        switch (interactionName.split("/")[0]) {
            case "program-pages" :
                switch (postTypeSelect) {
                    case "reviews" :
                        newPostData = {
                            reviewLikes: 0,
                            reviewText: e.target.elements.postTextInput.value,
                            reviewRating: e.target.elements.postReviewRating.value,
                            program_page: {
                                id: idInteractionName
                            },
                            users_permissions_user: user.id,
                        };
                        break;
                    case "qnas" :
                        newPostData = {
                            qnaLikes: 0,
                            qnaText: e.target.elements.postTextInput.value,
                            program_page: {
                                id: idInteractionName
                            },
                            users_permissions_user: user.id,
                        };
                        break;
                    default:
                        newPostData = {
                            blogLikes: 0,
                            blogText: e.target.elements.postTextInput.value,
                            program_page: {
                                id: idInteractionName
                            },
                            users_permissions_user: user.id,
                        };
                        break;
                }
                break;
            case "subject-pages" :
                switch (postTypeSelect) {
                    case "reviews" :
                        newPostData = {
                            reviewLikes: 0,
                            reviewText: e.target.elements.postTextInput.value,
                            reviewRating: e.target.elements.postReviewRating.value,
                            subject_page: {
                                id: idInteractionName
                            },
                            users_permissions_user: user.id,
                        };
                        break;
                    case "qnas" :
                        newPostData = {
                            qnaLikes: 0,
                            qnaText: e.target.elements.postTextInput.value,
                            subject_page: {
                                id: idInteractionName
                            },
                            users_permissions_user: user.id,
                        };
                        break;
                    default:
                        newPostData = {
                            blogLikes: 0,
                            blogText: e.target.elements.postTextInput.value,
                            subject_page: {
                                id: idInteractionName
                            },
                            users_permissions_user: user.id,
                        };
                        break;
                }
                break;
            default :
                switch (postTypeSelect) {
                    case "reviews" :
                        newPostData = {
                            reviewLikes: 0,
                            reviewText: e.target.elements.postTextInput.value,
                            reviewRating: e.target.elements.postReviewRating.value,
                            university_page: idInteractionName,
                            users_permissions_user: user.id,
                        };
                        break;
                    case "qnas" :
                        newPostData = {
                            qnaLikes: 0,
                            qnaText: e.target.elements.postTextInput.value,
                            university_page: idInteractionName,
                            users_permissions_user: user.id,
                        };
                        break;
                    default:
                        newPostData = {
                            blogLikes: 0,
                            blogText: e.target.elements.postTextInput.value,
                            university_page: idInteractionName,
                            users_permissions_user: user.id,
                        };
                        break;
                }
        }

        await handleAddPost({newPostData, postTypeSelect});

        switch (postTypeSelect) {
            case "reviews" :
                fetchReviewData();
                break;
            case "qnas" :
                fetchQnAData();
                break;
            default:
                fetchBlogData();
                break;
        }

        onClose();
    };

    return (
        <div className="bodyDivAdd">
            {isLoggedIn ? (
                <div className="bodyDiv">
                    <div className="addPostContainer">
                        <div className="crossDiv">
                            <img src={CrossBtn} className="crossBtn" alt="Cancel button" onClick={onClose}/>
                        </div>
                        <img src={SCLogoWhite} className="logo" alt="Student's Choice Logo"/>

                        <h1>Create post!</h1>

                        <form className="postForm" onSubmit={handleSubmit}>
                            <h2>What kind of post would you like to make?</h2>
                            <select className="postTypeSelect"
                                    name="postType"
                                    type="postType"
                                    value={postTypeSelect}
                                    onChange={(e) => setPostTypeSelect(e.target.value)}
                                    required>
                                <option value="">Select a post type</option>
                                <option value="reviews">Review</option>
                                <option value="blogs">Blog</option>
                                <option value="qnas">Question & Answer</option>
                            </select>

                            {postTypeSelect === "reviews" && (
                                <div>
                                    <h2>Please give your rating below</h2>
                                    <input
                                        className="postReviewRating"
                                        name="postReviewRating"
                                        type="decimal"
                                        placeholder="Enter rating (0.00 - 5.00)"
                                        min="0"
                                        max="5"
                                        required
                                    />
                                </div>
                            )}

                            <div className="emailDiv">
                            <textarea className="postTextInput" name="postTextInput" type="postTextInput"
                                      placeholder="What do you want to say?" required/>
                            </div>
                            <br/>

                            <button type="submit" className="submitButton" disabled={isSending}>
                                {isSending ? "Sending Post..." : "Send Post!"}
                                <img className="logoSmall" src={Next} alt="Sign in"/>
                            </button>
                            {error && <h4 className="error">{error}</h4>}

                        </form>


                    </div>
                </div>
            ) : (
                <>
                    {showLogin &&
                        <Login onClose={toggleLoginVisibility} loginMessage={"Please login to create a post!"}/>}
                </>
            )}
        </div>
    );

}

export default AddPostPopup;