import React, { useState } from 'react';

const DropDownButton = ({ onSelection }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [buttonText, setButtonText] = useState('Blog');

  // Updated options to include both text and ID
  const options = [
    { text: 'Reviews', id: 'review' },
    { text: 'Blog', id: 'blog' },
    { text: 'Ask Questions', id: 'qna' }
  ];

  const toggleDropdown = () => setIsOpen(!isOpen);

  const chooseOption = (option) => {
    setButtonText(option.text); // Display text in button
    setIsOpen(false);
    onSelection(option.id); // Call the onSelection callback with the option's ID
  };

  const buttonStyle = {
    backgroundColor: '#FFFFFF',
    border: 'none',
    borderRadius: '20px',
    padding: '10px 20px',
    cursor: 'pointer',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    fontSize: '6vmin',
    color: '#8B0000',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '90%',
    outline: 'none',
    margin: 'auto',
  };

  const dropdownStyle = {
    position: 'absolute',
    backgroundColor: '#FFFFFF',
    border: 'none',
    borderRadius: '20px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    marginLeft: '5%',
    marginTop: '5px',
    width: '90%', // Ensure the width matches the button for alignment
    zIndex: 1000, // Ensure the dropdown appears above other content
  };

  const optionStyle = {
    padding: '10px 20px',
    cursor: 'pointer',
    textAlign: 'center',
    backgroundColor: '#FFFFFF', 
    borderRadius: '20px',
    fontSize: '6vmin',
    color: '#8B0000',
    fontWeight: 'bold',
  };

  const arrowStyle = {
    display: 'inline-block',
    marginLeft: '5px',
    transition: 'transform 0.3s ease',
    transform: isOpen ? 'rotate(180deg)' : 'none'
  };

  return (
    <div style={{ position: 'relative' }}>
      <button onClick={toggleDropdown} style={buttonStyle}>
        {buttonText} <span style={arrowStyle}>&#x25BC;</span>
      </button>
      {isOpen && (
        <div style={dropdownStyle}>
          {options.map((option, index) => (
            <div
              key={index}
              style={optionStyle}
              onClick={() => chooseOption(option)}
            >
              {option.text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDownButton;
