// InteractionArea.js

import React, {useCallback, useEffect, useState} from 'react';
import {BASE_URL} from "../../API";

// Images
import CommentIcon from '../../images/icons/Comment.png';
import CommentIconRed from '../../images/icons/CommentRed.png';
import FAQIcon from '../../images/icons/FAQ.png';
import FAQIconRed from '../../images/icons/FAQRed.png';
import ReviewIcon from '../../images/icons/Review.png';
import ReviewIconRed from '../../images/icons/ReviewRed.png';
// import PlusIcon from '../../images/icons/Plus.png';
import PlusIconRed from '../../images/icons/PlusRed.png';

// Components
import ReviewCard from '../ReviewCard/ReviewCard';
import DropDownButton from '../DropDownButton/DropDownButton';
import AddPostPopup from "../AddPostPopup/AddPostPopup";


function InteractionArea(args) {
    const [showCreatePost, setShowCreatePost] = useState(false);
    const toggleCreatePostVisibility = () => setShowCreatePost(!showCreatePost);


    // use this to get values from Strapi
    const interactionName = args.interactionName

    const [selectedButton, setSelectedButton] = useState('blog'); // Initialize with 'blog'

    const [reviewsData, setReviewsData] = useState([]);
    const [blogsData, setBlogsData] = useState([]);
    const [qnasData, setQnasData] = useState([]);

    const fetchReviewData = useCallback(async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/${interactionName}
            ?populate[reviews][populate][users_permissions_user][populate][avatar]=true
            &populate[reviews][populate][users_permissions_user][fields][0]=username
            &populate[reviews][fields][0]=createdAt
            &populate[reviews][fields][1]=reviewLikes
            &populate[reviews][fields][2]=reviewText
            &populate[reviews][fields][3]=updatedAt
            &populate[reviews][fields][4]=reviewRating
            &sort[0]=updatedAt:desc`);
            const responseData = await response.json();

            setReviewsData(responseData.data.attributes.reviews.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [interactionName]);

    const fetchBlogData = useCallback(async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/${interactionName}
            ?populate[blogs][populate][users_permissions_user][populate][avatar]=true
            &populate[blogs][populate][users_permissions_user][fields][0]=username
            &populate[blogs][fields][0]=createdAt
            &populate[blogs][fields][1]=blogLikes
            &populate[blogs][fields][2]=blogText
            &populate[blogs][fields][3]=updatedAt
            &sort[0]=updatedAt:desc`);
            const responseData = await response.json();

            setBlogsData(responseData.data.attributes.blogs.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [interactionName]);

    const fetchQnAData = useCallback(async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/${interactionName}
            ?populate[qnas][populate][users_permissions_user][populate][avatar]=true
            &populate[qnas][populate][users_permissions_user][fields][0]=username
            &populate[qnas][fields][0]=createdAt
            &populate[qnas][fields][1]=qnaLikes
            &populate[qnas][fields][2]=qnaText
            &populate[qnas][fields][3]=updatedAt
            &sort[0]=updatedAt:desc`);
            const responseData = await response.json();

            setQnasData(responseData.data.attributes.qnas.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [interactionName]);

    useEffect(() => {
        fetchReviewData();
        fetchBlogData();
        fetchQnAData();
    }, [fetchReviewData, fetchBlogData, fetchQnAData]);

    const handleButtonClick = (id) => {
        setSelectedButton(id);
    };

    // Reviews section (put strapi data here for reviews using data collected by interactionName)
    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;

        return `${formattedDay}/${formattedMonth}/${year}`;
    };

    // check if we have any reviews for this area
    const reviewContent = reviewsData.length > 0 ? (
        <div className="justifyInteractionContent">
            {/* for each review, create a ReviewCard (add limits at a later stage) */}
            {reviewsData
                .sort((a, b) => new Date(b.attributes.updatedAt) - new Date(a.attributes.updatedAt))
                .map((review, index) => (
                    <ReviewCard
                        key={index}
                        name={review.attributes.users_permissions_user.data.attributes.username}
                        avatar={review.attributes.users_permissions_user.data.attributes.avatar.data ? review.attributes.users_permissions_user.data.attributes.avatar.data.attributes.formats.thumbnail : null}
                        like={review.attributes.reviewLikes}
                        desc={review.attributes.reviewText}
                        posttime={formatDate(review.attributes.createdAt)}
                        rating={review.attributes.reviewRating}
                    />
                ))}
        </div>
    ) : (
        <div className="noData">
            <p>There are no reviews to show here. <br></br> Be the first to post!</p>
        </div>
    );

    const blogContent = blogsData.length > 0 ? (
        <div className="justifyInteractionContent">
            {blogsData
                .sort((a, b) => new Date(b.attributes.updatedAt) - new Date(a.attributes.updatedAt))
                .map((review, index) => (
                    <ReviewCard
                        key={index}
                        name={review.attributes.users_permissions_user.data.attributes.username}
                        avatar={review.attributes.users_permissions_user.data.attributes.avatar.data ? review.attributes.users_permissions_user.data.attributes.avatar.data.attributes.formats.thumbnail : null}
                        like={review.attributes.blogLikes}
                        desc={review.attributes.blogText}
                        posttime={formatDate(review.attributes.createdAt)}
                    />
                ))}
        </div>
    ) : (
        <div className="noData">
            <p>There are no blogs to show here. <br></br> Be the first to post!</p>
        </div>
    );

    const qnaContent = qnasData.length > 0 ? (
        <div className="justifyInteractionContent">
            {qnasData
                .sort((a, b) => new Date(b.attributes.updatedAt) - new Date(a.attributes.updatedAt))
                .map((review, index) => (
                    <ReviewCard
                        key={index}
                        name={review.attributes.users_permissions_user.data.attributes.username}
                        avatar={review.attributes.users_permissions_user.data.attributes.avatar.data ? review.attributes.users_permissions_user.data.attributes.avatar.data.attributes.formats.thumbnail : null}
                        like={review.attributes.qnaLikes}
                        desc={review.attributes.qnaText}
                        posttime={formatDate(review.attributes.createdAt)}
                    />
                ))}
        </div>
    ) : (
        <div className="noData">
            <p>There are no questions to show here. <br></br> Be the first to ask a question!</p>
        </div>
    );

    return (
        <div className="mainActionArea">
            <div className="mobDropDown">
                <h1 className="sectionTitle2">Explore</h1>
                <DropDownButton onSelection={handleButtonClick}/>
            </div>

            <div className="actionBarStyle">

                <ActionButton
                    id="review"
                    image={ReviewIconRed}
                    selectedImage={ReviewIcon}
                    text="Reviews"
                    isSelected={selectedButton === 'review'}
                    onClick={() => handleButtonClick('review')}
                />

                <ActionButton
                    id="blog"
                    image={CommentIconRed}
                    selectedImage={CommentIcon}
                    text="Blog"
                    isSelected={selectedButton === 'blog'}
                    onClick={() => handleButtonClick('blog')}
                />

                <ActionButton
                    id="qna"
                    image={FAQIconRed}
                    selectedImage={FAQIcon}
                    text="Ask Questions"
                    isSelected={selectedButton === 'qna'}
                    onClick={() => handleButtonClick('qna')}
                />
            </div>

            {/* red line */}
            <div className="redLine"/>

            <div className="createButtonBackground" onClick={toggleCreatePostVisibility}>
                <p id="postButton" className="createButtonText">
                    Add Post
                </p>
                <img src={PlusIconRed} className="createButtonImage" alt="Plus Icon"/>
            </div>

            {showCreatePost && <AddPostPopup onClose={toggleCreatePostVisibility} interactionName={interactionName}
                                             fetchReviewData={fetchReviewData} fetchBlogData={fetchBlogData}
                                             fetchQnAData={fetchQnAData}/>}


            <div id="showDiv" className="interactionPadding">
                {selectedButton === 'review' && reviewContent}
                {selectedButton === 'blog' && blogContent}
                {selectedButton === 'qna' && qnaContent}
            </div>
        </div>
    );
}

function ActionButton({id, image, selectedImage, text, isSelected, onClick}) {
    return (
        <div
            className={`actionButtonContainerStyle ${isSelected ? 'selectedOutline' : ''}`}
            onClick={onClick}
        >
            <h3>{text}</h3>
            <img src={isSelected ? selectedImage : image} className="actionButtonImageStyle" alt={`${image} Icon`}/>
        </div>
    );
}

export default InteractionArea;