import React, {useState} from "react";
import Next from '../../images/logos/nextIcon.png';
import CrossBtn from "../../images/icons/CrossBtncross.png";
import SCLogoWhite from "../../images/logos/SCLogoWhiteBG.png";
import {API} from "../../API";
import {setToken} from "../../components/AuthenticationInforamtionAndManager/AuthenticationTokens";
import {
    GetAuthenticationInformation
} from "../../components/AuthenticationInforamtionAndManager/AuthenticationInformation";
import CreateProfile from "../Create Profile/Create Profile";

function SignUp({onClose, onSignUpClick}) {
    const [showCreateProfile, setShowCreateProfile] = useState(false);

    return (
        <>
            {showCreateProfile ? (
                <CreateProfile onClose={onClose}/>
            ) : (
                <SignUpInnerContainer onClose={onClose} onSignUpClick={onSignUpClick} onSignUpNextStep={() => setShowCreateProfile(true)}/>
            )}
        </>
    );

    function SignUpInnerContainer({onClose, onSignUpClick, onSignUpNextStep}) {
        const {setUser} = GetAuthenticationInformation();

        const [isLoading, setIsLoading] = useState(false);

        const [error, setError] = useState("");

        const CreateNewUser = async (values) => {
            setIsLoading(true);
            try {
                const response = await fetch(`${API}/auth/local/register`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(values),
                });

                const data = await response.json();
                if (data?.error) {
                    throw data?.error;
                } else {
                    // set the token
                    setToken(data.jwt);

                    // set the user
                    setUser(data.user);

                    setShowCreateProfile(true);
                }
            } catch (error) {
                console.error(error);
                setError(error?.message ?? "Error!");

            } finally {
                setIsLoading(false);
            }
        };

        const handleSubmit = (e) => {
            e.preventDefault();

            const username = e.target.elements.username.value;
            const email = e.target.elements.email.value;
            const password = e.target.elements.password.value;

            CreateNewUser({username, email, password});
        };
        return (
            <div className="loginContainer">
            <div className="crossDiv">
                <img src={CrossBtn} className="crossBtn" alt="Cancel button" onClick={onClose}/>
            </div>
            <img src={SCLogoWhite} className="logo" alt="Student's Choice Logo"/>
            <h1>Welcome to Student's Choice</h1>

            <form className="loginForm" onSubmit={handleSubmit}>
                <h2>Display Name</h2>
                <div className="emailDiv">
                    <input className="emailEntryInput" name="username" type="username"
                           placeholder="What should we call you" required/>
                </div>

                <h2>Email</h2>
                <div className="emailDiv">
                    <input className="emailEntryInput" name="email" type="email" placeholder="Enter your email"
                           required/>
                </div>

                <h2>Password</h2>
                <div className="emailDiv">
                    <input className="emailEntryInput" name="password" type="password"
                           placeholder="Enter your password" required/>
                </div>

                <button type="submit" className="submitButton" disabled={isLoading} style={{marginTop: '10%'}}>
                    {isLoading ? "Signing in..." : "Sign up"}
                    <img className="logoSmall" src={Next} alt="Sign in"/>
                </button>
                {error && <h4 className="error">{error}</h4>}
            </form>

            <br/>

            <button className="submitButtonInverted" onClick={onSignUpClick}>
                Login instead
            </button>
        </div>
        );
    }
}

export default SignUp;