import {useEffect, useState} from "react";
import {BASE_URL} from "../../API";
import {useNavigate} from "react-router-dom";

function Subjects() {
    const [fields, setFields] = useState([]);
    const [universities, setUniversities] = useState([]);

    const [fieldId, setFieldId] = useState(0);
    const [graduationLevel, setGraduationLevel] = useState("");
    const [universityId, setUniversityId] = useState(0);
    const [searchResult, setSearchResult] = useState([]);

    const [showResults, setShowResults] = useState(false);

    const navigate = useNavigate();

    const handleFieldClick = (id) => {
        setFieldId(id === fieldId ? 0 : id);
    };

    const handleLevelClick = (level) => {
        setGraduationLevel(graduationLevel === level ? "" : level);
    };

    const handleUniversityClick = (id) => {
        setUniversityId(id === universityId ? 0 : id);
    };

    const handleResultClick = (universityId, subjectid) => {
        navigate(`/universities/${universityId}/subject/${subjectid}`);
    };

    useEffect(() => {

        const fetchData = async () => {
            try {
                let url = `${BASE_URL}/api/subject-pages?populate[university_page][populate]=true&populate[subject_field_component][populate]=true`;

                if (universityId !== 0) {
                    url += `&filters[university_page][id][$eq]=${universityId}`;
                }
                if (graduationLevel !== "") {
                    url += `&filters[subjectGraduationLevel][$eq]=${graduationLevel}`;
                }
                if (fieldId !== 0) {
                    url += `&filters[subject_field_component][id][$eq]=${fieldId}`;
                }

                const response = await fetch(url);

                const responseData = await response.json();

                setSearchResult(responseData.data);
                setShowResults(true);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();

    }, [fieldId, graduationLevel, universityId]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${BASE_URL}/api/subject-field-components?fields[0]=subjectFieldName
                    `);

                const responseData = await response.json();

                setFields(responseData.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }

            try {
                const response = await fetch(
                    `${BASE_URL}/api/university-pages?populate[universityLogo][fields][0]=alternativeText&populate[universityLogo][fields][1]=formats`
                );
                const responseData = await response.json();

                setUniversities(responseData.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (

        <div className='subjectsPage'>

            <h1 className='subjectsTitle'>Subjects</h1>

            <div className='fieldsBubble'>

                <h2 className='fieldsTitle'>Field</h2>

                <div className='fields'>
                    {fields.map((field) => (
                        <bub
                            key={field.id}
                            id={field.id}
                            className={field.id === fieldId ? "selected" : ""}
                            onClick={() => handleFieldClick(field.id)}
                        >
                            {field.attributes.subjectFieldName}
                        </bub>
                    ))}
                </div>
            </div>

            <div className='levelBubble'>

                <h2 className='levelTitle'>Graduation level</h2>

                <div className='levels'>
                    <bub
                        onClick={() => handleLevelClick("Undergraduate")}
                        className={"Undergraduate" === graduationLevel ? "selected" : ""}
                        id={"undergraduate"}>
                        Undergraduate
                    </bub>
                    <bub
                        onClick={() => handleLevelClick("Postgraduate")}
                        className={"Postgraduate" === graduationLevel ? "selected" : ""}
                        id={"postgraduate"}>
                        Postgraduate
                    </bub>
                    <bub
                        onClick={() => handleLevelClick("Research")}
                        className={"Research" === graduationLevel ? "selected" : ""}
                        id={"research"}>
                        Research
                    </bub>
                </div>
            </div>

            <div className='universitiesBubble'>

                <h2 className='universitiesTitleProgram shuffle'>Universities</h2>

                <div className='universities'>

                    <button
                        key={0}
                        className={`allUnis ${0 === universityId ? "selected" : ""}`}
                        onClick={() => handleUniversityClick(0)}
                    >
                        <div className='allUnisTitle'>
                            All universities
                        </div>
                    </button>


                    {universities.slice(0, 6).map((university) => (
                        <button
                            key={university.id}
                            className={`uni1Button ${university.id === universityId ? "selected" : ""}`}
                            onClick={() => handleUniversityClick(university.id)}
                        >
                            <img
                                src={`${BASE_URL}${university.attributes.universityLogo.data.attributes.formats.thumbnail.url}`}
                                className="uniLogo1Logo"
                                alt={university.attributes.universityLogo.data.attributes.alternativeText}
                            />
                            <button className="uni1Tick" onClick={(e) => {
                                e.stopPropagation();
                                handleUniversityClick(university.id);
                            }}>
                                {university.id === universityId && <span>&#10003;</span>}
                            </button>
                        </button>
                    ))}
                </div>

            </div>

            <div className='resultsBubble'>

                <h2 className='resultsTitle'>Results</h2>

                {showResults && searchResult.length > 0 ? (
                    <div className='results'>
                        {Array.from(new Set(searchResult.map(result => result.attributes.university_page.data.id))).map((universityId, index) => {
                            const universityResults = searchResult.filter(result => result.attributes.university_page.data.id === universityId);
                            const universityName = universityResults[0].attributes.university_page.data.attributes.universityName;

                            return (
                                <div key={index}>
                                    <div className='uni1TitleBubble'>
                                        <div className='uni1Title'>{universityName}</div>
                                    </div>
                                    <div className='uniResults1'>
                                        {universityResults.map((result, resultIndex) => (
                                            <bub key={resultIndex}
                                                 onClick={() => handleResultClick(universityId, result.id)}
                                            >
                                                {result.attributes.subjectName}
                                            </bub>
                                        ))}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className='results'>
                        <div className='uni1TitleBubble'>
                            <div className='uni1Title'>No Results</div>
                        </div>
                    </div>
                )}
            </div>

        </div>
    );
}

export default Subjects;

// TODO: make filtering options to sort by university and to sort by rating (where each rating is in buckets of how many stars still in order)
// TODO: make fields, university logos, and results dynamic lists and not static