import {useNavigate} from "react-router-dom";
import {BASE_URL} from "../../API";
import {useEffect, useState} from "react";
import Star from "../../images/icons/StarYellow.png";

function Universities() {
    const [universities, setUniversities] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [showResults, setShowResults] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        // Fetch data from API
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${BASE_URL}/api/university-pages?populate[universityLogo][fields][0]=alternativeText&populate[universityLogo][fields][1]=formats&sort[0]=universityRating:desc`
                );
                const responseData = await response.json();

                setUniversities(responseData.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleClick = (id) => {
        navigate(`/universities/${id}`);
    };

    const handleSearch = async () => {
        try {
            const response = await fetch(
                `https://backendstudentschoicedev.sithumd.com/api/fuzzy-search/search?query=${searchQuery}`
            );
            const searchData = await response.json();

            setSearchResult(searchData['university-pages']);
            setShowResults(true);
        } catch (error) {
            console.error('Error searching:', error);
        }
    };

    useEffect(() => {
        if (showResults) {
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        }
    }, [showResults]);

    return (

        <div className='universitiesPage'>

            <h1 className='universitiesTitle'>Universities</h1>

            <div className='popularUniversitiesBubble'>

                <h2 className='popularUniversitiesTitle'>Most Popular</h2>

                <div className='logoButtons'>
                    {universities.slice(0, 6).map((university) => (
                        <button key={university.id} onClick={() => handleClick(university.id)}>
                            <img src={BASE_URL +
                                university.attributes.universityLogo.data.attributes.formats.thumbnail.url}
                                 className="uniLogo1Logo"
                                 alt={university.attributes.universityLogo.data.attributes.alternativeText}/>
                        </button>
                    ))}
                </div>

                <div className='searchUniversitiesTitle'>Search for universities</div>

                <div className='searchBar'>
                    <input
                        className='searchUniversitiesText'
                        placeholder='Search university name...'
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') handleSearch();
                        }}
                    />
                    <button className='searchButton' onClick={handleSearch}>
                        Search
                    </button>
                </div>
            </div>

            <br></br>

            {showResults && (
                <div className='resultsBubble'>
                    <div className='resultsTitle'>Results</div>
                    <ul className='resultsList'>
                        {searchResult.slice(0, 6).map((university) => (
                            <li key={university.id} onClick={() => handleClick(university.id)}>
                                <u>{university.universityRating} <img src={Star} class="programAndSubjectImg" alt="Star" width="1%"/> - {university.universityName}
                                </u>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

        </div>);
}

export default Universities;