import SCLogo from '../../images/logos/SCLogo.png';
import SearchWhite from '../../images/icons/SearchWhite.png';
import MenuBars from '../../images/icons/MenuBars.png';

import Login from '../../user/Login/Login';
import Profile from '../../user/Profile Stats/Profile Stats';

import React, {useEffect, useState} from 'react';
import {Link, Outlet} from "react-router-dom";

import {GetAuthenticationInformation} from "../AuthenticationInforamtionAndManager/AuthenticationInformation";


function Menubar() {
    const [isMobileView, setIsMobileView] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showProfile, setShowProfile] = useState(false);

    useEffect(() => {
        const updateView = () => {
            setIsMobileView(window.innerWidth <= 600);
        };
        updateView();

        window.addEventListener('resize', updateView);

        return () => {
            window.removeEventListener('resize', updateView);
        };
    }, []);

    const toggleLoginVisibility = () => setShowLogin(!showLogin); // Function to toggle login visibility
    const toggleProfileVisibility = () => setShowProfile(!showProfile); // Function to toggle profile visibility

    return (
        <div style={{margin: '0', width: '100vw', height: '7vh'}}>
            {isMobileView ? <MobileMenuBar toggleLoginVisibility={toggleLoginVisibility}
                                           toggleProfileVisibility={toggleProfileVisibility}/> :
                <DesktopMenuBar toggleLoginVisibility={toggleLoginVisibility}
                                toggleProfileVisibility={toggleProfileVisibility} showLogin={showLogin}/>}
            {showLogin && <Login onClose={toggleLoginVisibility} loginMessage={"Welcome to Student's Choice"}/>}
            {showProfile && <Profile onClose={toggleProfileVisibility}/>}
        </div>
    );
}


function MobileMenuBar({toggleLoginVisibility, toggleProfileVisibility}) {
    const [isMobTextVisible, setIsMobTextVisible] = useState(false);
    const [areLinksVisible, setAreLinksVisible] = useState(false);

    const toggleMobText = () => {
        if (!isMobTextVisible) {
            setIsMobTextVisible(true);
            setTimeout(() => setAreLinksVisible(true), 250);
        } else {
            closeMenu();
        }
    };

    const closeMenu = () => {
        setAreLinksVisible(false);
        setTimeout(() => setIsMobTextVisible(false), 100);
    };

    const handleLinkClick = () => {
        closeMenu();
    };

    const {user} = GetAuthenticationInformation();

    return (
        <>
            <div className="mobileLargeHeader" style={{position: 'relative', zIndex: 100}}>
                <div className="headerBackgroundMob">
                    <Link to="/" style={{textDecoration: 'none', height: '100%'}} onClick={handleLinkClick}>
                        <img src={SCLogo} alt="Logo" className="headerImageAlignment"/>
                    </Link>
                    <div className="menuBarAlignment">
                        <img src={SearchWhite} alt="Search Button" className="searchBarImage" />
                        <img src={MenuBars} alt="Menu Button" className="menuBarImage" onClick={toggleMobText}/>
                    </div>
                </div>
                <div className={isMobTextVisible ? "mobTextEnd" : "mobTextStart"}>
                    <div className={`${areLinksVisible ? "links visible" : "links"}`}>
                        <Link to="/" style={{textDecoration: 'none'}} onClick={handleLinkClick}>
                            <p>Home</p>
                        </Link>
                        <Link to="/universities" style={{textDecoration: 'none'}} onClick={handleLinkClick}>
                            <p>Universities</p>
                        </Link>
                        <Link to="/programs" style={{textDecoration: 'none'}} onClick={handleLinkClick}>
                            <p>Programs</p>
                        </Link>
                        <Link to="/subjects" style={{textDecoration: 'none'}} onClick={handleLinkClick}>
                            <p>Subjects</p>
                        </Link>
                        {user ? (
                            <>
                                <p onClick={() => {toggleProfileVisibility(); handleLinkClick();}}>Profile</p>
                            </>
                        ) : (
                            <>
                                <p onClick={() => {toggleLoginVisibility(); handleLinkClick();}}>Login</p>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Outlet/>
        </>
    );
}



function DesktopMenuBar({toggleLoginVisibility, toggleProfileVisibility}) {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [showResults, setShowResults] = useState(false);

    const {user} = GetAuthenticationInformation();
    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearch = async () => {
        try {
            const response = await fetch(
                `https://backendstudentschoicedev.sithumd.com/api/fuzzy-search/search?query=${searchQuery}`
            );
            const searchData = await response.json();

            setSearchResult(searchData['university-pages']);
            setShowResults(true);
        } catch (error) {
            console.error('Error searching:', error);
        }
    };

    return (
        <>
            <div className="headerBackground">
                <div className="headerContentAlignment">
                    <Link to="/" style={{textDecoration: 'none', height: '100%'}}>
                        <img src={SCLogo} alt="Logo" className="headerImageAlignment"/>
                    </Link>
                    <Link to="/universities" style={{textDecoration: 'none'}}>
                        <p>Universities</p>
                    </Link>
                    <Link to="/programs" style={{textDecoration: 'none'}}>
                        <p>Programs</p>
                    </Link>
                    <Link to="/subjects" style={{textDecoration: 'none'}}>
                        <p>Subjects</p>
                    </Link>

                    {/* Change search */}
                    <div className="searchDivAlignment">
                        <input
                            className="searchInputStyling"
                            placeholder='Search'
                            value={searchQuery}
                            onChange={handleInputChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') handleSearch();
                            }}
                        />
                        <img
                            src={SearchWhite}
                            alt="Search Button"
                            className="searchBarButton"
                            onClick={handleSearch}
                        />
                    </div>

                    {user ? (
                        <>
                            <p className="loginBorder" onClick={toggleProfileVisibility}>Profile</p>
                        </>
                    ) : (
                        <>
                            <p className="loginBorder" onClick={toggleLoginVisibility}>Login</p>
                        </>
                    )}
                </div>
            </div>
            {showResults && (
                <div className="searchResultsContainer">
                    <div className="searchResultsOverlay"></div>
                    <div className="searchResults">
                        <ul>
                            {searchResult.map((item) => (
                                <li key={item.id}>
                                    <Link to={`/universities/${item.id}`}>
                                        {item.universityName}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
            <Outlet/>
        </>
    );
}

export default Menubar;
