import '../style.css';
import {Link, useNavigate} from "react-router-dom";

// Images
import Confetti from '../images/miscellaneous/Confetti.png';
import GradCap from '../images/icons/graduation.png';
import Book from '../images/icons/write.png';
import Atom from '../images/icons/atomic.png';
import {useEffect, useState} from "react";
import {BASE_URL} from "../API";
import Star from '../images/icons/StarYellow.png';

function Home() {
    const [universities, setUniversities] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch data from API
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${BASE_URL}/api/university-pages?populate[universityLogo][fields][0]=alternativeText&populate[universityLogo][fields][1]=formats&sort[0]=universityRating:desc`
                );
                const responseData = await response.json();

                setUniversities(responseData.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleClick = (id) => {
        navigate(`/universities/${id}`);
    };

    return (
        <div style={{width: '100vw'}}>
            <div className="primaryDiv">
                <img className="confettiSize" src={Confetti} alt="Confetti"/>

                {/* Pills */}
                <div className="pillContainer">
                    {universities && universities.slice(0, 5).map((uni, index) => (
                        <Pill index={index}
                              image={`${BASE_URL}${uni.attributes.universityLogo.data.attributes.formats.thumbnail.url}`}
                              onClick={() => handleClick(uni.id)}
                        />
                    ))}
                </div>

                <div className="contentDiv">
                    <h1 className="titleText">Welcome to <br></br>Students Choice</h1>
                    <h2 className="subtext">From students, for students</h2>
                    <h3 className="subsubText">Discover universities, share experiences, make informed decisions</h3>
                    {/* Buttons */}
                    <div className="btnDiv">
                        <Link className="linkStyle" to="/universities">
                            <Button image={GradCap} text={"Check out some universities"}/>
                        </Link>
                        <Link className="linkStyle" to="/programs">
                            <Button image={Book} text={"Explore offered programs"}/>
                        </Link>
                        <Link className="linkStyle" to="/subjects">
                            <Button image={Atom} text={"Find your next subjects"}/>
                        </Link>
                    </div>

                    <div className="redLine" />

                    <h1 className="redText">Here are some of our favourites, check them out!</h1>

                    {universities && universities.slice(0, 5).map((uni, index) => (
                        <UniRow key={index}
                                uniName={uni.attributes.universityName}
                                image={`${BASE_URL}${uni.attributes.universityLogo.data.attributes.formats.thumbnail.url}`}
                                rating={uni.attributes.universityRating}
                                onClick={() => handleClick(uni.id)} />
                    ))}
                </div>
            </div>
        </div>

    );
}

function Pill({ index, image, onClick }) {
    let top;
    let left;

    switch (index) {
        case 0:
            top = -10;
            left = -100;
            break;
        case 1:
            top = -5;
            left = -75;
            break;
        case 2:
            left = -65;
            break;
        case 3:
            top = 5;
            left = -75;
            break;
        case 4:
            top = 10;
            left = -100;
            break;
        default:
            break;
    }

    const style = {
        transform: `rotate(${-30 + (index * 15)}deg)`,
        position: 'relative',
        top: top ? `${top}%` : undefined,
        left: left ? `${left}%` : undefined
    };

    return (
        <div className="pill" style={style} onClick={onClick}>
            <img className="pillImgSize" src={image} alt="Uni logo"/>
        </div>
    );
}

function Button( { image, text }) {
    return (
        <div className="btnSize">
            <img className="btnImgSize" src={image} alt="Uni logo"/>
            <h1>{text}</h1>
        </div>
    );
}

function UniRow( { image, uniName, rating = "Not rated", onClick}) {
    return (
        <div className="uniRowSize" onClick={onClick}>
            <div class="uniTextDiv">
                <h1>{uniName}</h1>
                <div class="starFlex">
                    <p>{rating}</p>
                    <img src={Star} class="starSize" alt="Star"/>
                </div>

            </div>
            <img className="uniRowImgSize" src={image} alt="ANU Logo" />
        </div>
    );
}

export default Home