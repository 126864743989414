import CrossBtn from '../../images/icons/CrossBtncross.png';
import React, {useEffect, useState} from "react";
import {API, BASE_URL, BEARER} from "../../API";
import {
    GetAuthenticationInformation
} from "../../components/AuthenticationInforamtionAndManager/AuthenticationInformation";
import {getToken} from "../../components/AuthenticationInforamtionAndManager/AuthenticationTokens";
import {useNavigate} from "react-router-dom";
import DefaultProfilePic from "../../images/miscellaneous/DefaultProfilePhoto.jpg";

function CreateProfile({onClose}) {
    const { user, setUser } = GetAuthenticationInformation();

    const [institution, setInstitution] = useState('The Australian National University');
    const [studentStatus, setStudentStatus] = useState('Looking for University');
    const [universities, setUniversities] = useState([]);
    const [filteredUniversities, setFilteredUniversities] = useState([]);
    const [photoFile, setPhotoFile] = useState(DefaultProfilePic);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const fetchUniversities = () => {
            fetch(`${API}/university-pages?fields[0]=universityName`)
                .then(response => response.json())
                .then(data => {
                    setUniversities(data.data);
                })
                .catch(error => {
                    console.error('Error fetching universities:', error);
                });
        };

        fetchUniversities();
    }, []);

    const handleUniversityInputChange = (e) => {
        const input = e.target.value.toLowerCase();
        const filtered = universities.filter(u => u.attributes.universityName.toLowerCase().includes(input));
        setFilteredUniversities(filtered);
        setInstitution(input);
    };

    const handleUniversityItemClick = (universityName) => {
        setInstitution(universityName);
        setFilteredUniversities([]);
    };

    const handleUpdateProfile = async () => {
        const updatedUser = {
            ...user,
            university: {
                ...user?.university,
                universityName: institution
            },
            studentStatus: studentStatus
        };

        const formData = new FormData();
        formData.append("files", photoFile);

        const valuesChanged = (
            updatedUser.university?.universityName !== user.university?.universityName ||
            updatedUser.studentStatus !== user.studentStatus
        );

        if (photoFile !== DefaultProfilePic) {
                try {
                    const token = getToken();

                    setLoading(true);

                    fetch(`${API}/upload`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `${BEARER} ${token}`
                        },
                        body: formData
                    })
                        .then(response => response.json())
                        .then(data => {
                            setError("Profile Updated!");
                        })
                        .catch(error => {
                            console.error('Error uploading file:', error);
                            setError(error?.message ?? "Error!");
                        })
                        .finally(() => {
                            setLoading(false);
                        });

                } catch (error) {
                    console.error(JSON.stringify(error));
                }
        } else if (valuesChanged) {
            setUser(updatedUser);
            const token = getToken();

            setLoading(true);

            fetch(`${API}/users/${user.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${BEARER} ${token}`
                },
                body: JSON.stringify(updatedUser),
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Success:', data);
                    setError("Profile Updated!");
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setError(error?.message ?? "Error!");
                })
                .finally(() => {
                    setLoading(false);
                    navigate("/profile", {replace: true});
                    onClose();
                });
        } else {
            setError("No values changed!");
        }
    };

    return (

        <div className="loginContainer">

            <div className='editProfilePage'>

                <div className='editProfileBubble'>

                    <div className="statsCrossDiv">
                        <img src={CrossBtn} className="statsCrossBtn" alt="Cancel button" onClick={onClose}/>
                    </div>

                    <h1 className='titleText'>Some additional information please!</h1>


                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                    <img src={user.avatar ? `${BASE_URL}${photoFile}` : DefaultProfilePic} className='profilePhoto'
                             alt='Profile photo'/>
                    <br/>
                    <input type="file" onChange={(e) => setPhotoFile(e.target.files[0])} accept="image/*"/>


                    <div className='inputs'>
                        <div className='inputQuadrant'>
                            <div className='studentStatusTitle'>Student status</div>
                            <select className='studentStatusInput' defaultValue={'Looking for University'}
                                    onChange={(e) => setStudentStatus(e.target.value)}>
                                <option value='Undergraduate'>Undergraduate</option>
                                <option value='Postgraduate'>Postgraduate</option>
                                <option value='Looking for University'>Looking for a University</option>
                            </select>
                        </div>

                        <div className='inputQuadrant'>
                            <div className='universityTitle'>University</div>
                            <input className='universityInput' value={institution}
                                   onChange={handleUniversityInputChange} type='text'/>
                            {filteredUniversities.length > 0 && (
                                <ul className="universityList">
                                    {filteredUniversities.map((university, index) => (
                                        <h2 key={index}
                                            onClick={() => handleUniversityItemClick(university.attributes.universityName)}>{university.attributes.universityName}</h2>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>

                    <button className="submitButtonLogout" onClick={handleUpdateProfile}>
                        {loading ? "Finishing Profile..." : "Finish Profile"}
                    </button>
                    {error && <h4 className="error">{error}</h4>}
                </div>
            </div>
        </div>
    );
}

export default CreateProfile