import {
    GetAuthenticationInformation
} from "../../components/AuthenticationInforamtionAndManager/AuthenticationInformation";
import {useLocation, useNavigate} from "react-router-dom";
import {getToken, removeToken} from "../../components/AuthenticationInforamtionAndManager/AuthenticationTokens";
import React, {useEffect, useState} from "react";
import EditProfile from "../Edit Profile/Edit Profile";
import CrossBtn from '../../images/icons/CrossBtncross.png';
import {API, BASE_URL, BEARER} from "../../API";
import DefaultProfilePic from "../../images/miscellaneous/DefaultProfilePhoto.jpg";

function ProfileStats({onClose}) {
    const [showEditUp, setShowEdit] = useState(false);

    const {user, setUser} = GetAuthenticationInformation();

    const navigate = useNavigate();
    const location = useLocation();
    const setUserLogout = () => {
        console.log("adw")
        removeToken();
        setUser(undefined);
        if (location.pathname !== '/profile') {
            onClose();
        }
        navigate("/", {replace: true});
    };

    const CloseWindow = () => {
        if (location.pathname === "/profile") {
            navigate("/", {replace: true});
        } else {
            onClose();
        }
    }
    useEffect( () => {
        const fetchLoggedInUser = async (token) => {
            try {
                const response = await fetch(`${API}/users/me?fields[0]=username&fields[1]=email&fields[2]=studentStatus&populate[university][fields][0]=universityName&populate[avatar][fields][0]=formats&populate[avatar][fields][1]=url&populate[comments][fields][0]=id&populate[reviews][fields][0]=&populate[blogs][fields][0]=&populate[qnas][fields][0]=`, {
                    headers: {Authorization: `${BEARER} ${token}`},
                });

                const data = await response.json();

                setUser(data);
            } catch (error) {
                console.error(error);
            }
        }

        const token = getToken();

        if (token) {
            const timeoutId = setTimeout(() => {
                fetchLoggedInUser(token);
            }, 1000);

            return () => clearTimeout(timeoutId);
        }
    }, [setUser]);

    return (
        <div className="bodyDiv">
            {showEditUp ? (
                <EditProfile onClose={CloseWindow} onEditClick={() => setShowEdit(false)}/>
            ) : (
                <StatsContainer onClose={CloseWindow} onEditClick={() => setShowEdit(true)}
                                setUserLogout={setUserLogout} user={user}/>
            )}
        </div>

    );
}

function StatsContainer({onClose, onEditClick, setUserLogout, user}) {
    return (
    <div className="loginContainer">
        <div className='profileStatsPage'>
            {user && (
                <div className='profileStatsBubble'>

                    <div className="statsCrossDiv">
                        <button className='editProfileButton' onClick={onEditClick}>Edit Profile</button>
                        <img src={CrossBtn} className="statsCrossBtn" alt="Cancel button" onClick={onClose}/>
                    </div>

                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                    <img src={user.avatar ? `${BASE_URL}${user.avatar.formats.thumbnail.url}` : DefaultProfilePic}
                         className='profilePhoto' alt='Profile photo'></img>

                    <h1 className='username'>Hey, {user?.username}!</h1>
                    {user.university && (
                    <h2 className='institution'>{user?.university.universityName}</h2>
                        )}
                    <br></br>

                    <h1 style={{color: "#4F4F4F"}}>Here are some of your stats</h1>
                    <br></br><br></br>

                    <div className='stats'>

                        <div>You've asked {user.qnas ? `${user.qnas.length}` : "no"} questions</div>

                        <div>You've created {user.blogs ? `${user.blogs.length}` : "no"} blogs</div>

                        <div>You've made {user.reviews ? `${user.reviews.length}` : "no"} reviews</div>

                    </div>

                    <button className="submitButtonLogout" onClick={setUserLogout}>
                        <p>Logout</p>
                    </button>
                </div>
            )}
        </div>
    </div>
    )
}


export default ProfileStats;