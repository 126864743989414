// Images
import CrossBtn from '../../images/icons/CrossBtncross.png';
import SCLogoWhite from '../../images/logos/SCLogoWhiteBG.png';
// import Apple from '../../images/logos/appleLogin.png';
// import Google from '../../images/logos/googleLogin.png';
import Next from '../../images/logos/nextIcon.png';

import React, {useState} from "react";
import {
    GetAuthenticationInformation
} from "../../components/AuthenticationInforamtionAndManager/AuthenticationInformation";
import {setToken} from "../../components/AuthenticationInforamtionAndManager/AuthenticationTokens";
import {useNavigate} from "react-router-dom";
import SignUp from "../SignUp/SignUp";

import {API} from "../../API";

function Login({onClose, loginMessage}) {
    const [showSignUp, setShowSignUp] = useState(false);

    return (
        <div className="bodyDiv">
            {showSignUp ? (
                <SignUp onClose={onClose} onSignUpClick={() => setShowSignUp(false)}/>
            ) : (
                <LoginContainer loginMessage={loginMessage} onClose={onClose} onSignUpClick={() => setShowSignUp(true)}/>
            )}
        </div>
    );
}

function LoginContainer({onClose, onSignUpClick, loginMessage}) {
    const navigate = useNavigate();

    const {setUser} = GetAuthenticationInformation();

    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState("");

    const userLogin = async (values) => {
        setIsLoading(true);
        try {
            const value = {
                identifier: values.email,
                password: values.password,
            };

            const response = await fetch(`${API}/auth/local`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(value),
            });

            const data = await response.json();

            if (data?.error) {
                throw data?.error;
            } else {
                setToken(data.jwt);

                setUser(data.user);

                navigate("/profile", {replace: true});

                onClose();
            }
        } catch (error) {
            console.error(error);
            setError(error?.message ?? "Error!");
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const email = e.target.elements.email.value;
        const password = e.target.elements.password.value;
        userLogin({email, password});
    };

    return (
        <div className="loginContainer">
            <div className="crossDiv">
                <img src={CrossBtn} className="crossBtn" alt="Cancel button" onClick={onClose}/>
            </div>
            <img src={SCLogoWhite} className="logo" alt="Student's Choice Logo"/>
            <h1>{loginMessage}</h1>

            <form className="loginForm" onSubmit={handleSubmit}>
                <h2>Email</h2>
                <div className="emailDiv">
                    <input className="emailEntryInput" name="email" type="email" placeholder="Enter your email"
                           required/>
                </div>

                <h2>Password</h2>
                <div className="emailDiv">
                    <input className="emailEntryInput" name="password" type="password"
                           placeholder="Enter your password" required/>
                </div>
                <br/>

                <button type="submit" className="submitButton" disabled={isLoading}>
                    {isLoading ? "Logging in..." : "Log In"}
                    <img className="logoSmall" src={Next} alt="Sign in"/>
                </button>
                {error && <h4 className="error">{error}</h4>}
            </form>

            <br/>


            {/* <h2>Or sign in with</h2>
            <div className="signInWith">
                <img src={Apple} className="signInLogo" alt="Sign in with Apple"/>
                <img src={Google} className="signInLogo" alt="Sign in with Google"/>
            </div> */}

            <button className="submitButtonInverted" onClick={onSignUpClick}>
                New? Sign Up
            </button>
        </div>
    );
}

export default Login;