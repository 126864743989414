import ChevronLeft from '../../../images/icons/ChevronLeft.png';
import { useNavigate, useParams } from 'react-router-dom';

import InteractionArea from '../../../components/InteractionArea/InteractionArea';
import { useEffect, useState } from "react";
import { BASE_URL } from "../../../API";

function DetailView(args) {
    const { idUniversity, idDetail } = useParams();
    const navigate = useNavigate();
    const [detail, setDetail] = useState({});
    const [university, setUniversity] = useState({});
    const [type, setType] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (type) {
                    const response = await fetch(
                        `${BASE_URL}/api/${type}-pages/${idDetail}
                    ?fields[0]=${type}Name
                    &fields[1]=${type}Description
                    &fields[2]=${type}GraduationLevel
                    `);
                    const responseData = await response.json();

                    setDetail(responseData.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [idDetail, type]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${BASE_URL}/api/university-pages/${idUniversity}?fields[0]=universityName
                    `);
                const responseData = await response.json();

                setUniversity(responseData.data.attributes);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [idUniversity]);

    useEffect(() => {
        setType(window.location.pathname.includes('/program/') ? 'program' : 'subject');
    }, []);

    const handleBack = () => {
        navigate(`/universities/${idUniversity}`);
    };

    return (
        <div style={{ margin: '0', width: '100vw', height: '100vh', position: 'relative' }}>
            <div style={{ marginLeft: '2%' }}>
                {university && (
                    <div className="chevFlex" onClick={handleBack}>
                        <img className="imgStyle" src={ChevronLeft} alt="Back arrow" />
                        <h1 class="uniTitleDetail">{university.universityName}</h1>
                    </div>
                )}

                {detail.attributes && (
                    <>
                        <h3 class="courseNameDetail">{detail.attributes[`${type}Name`]}</h3>
                        <h4 class="gradLev">{detail.attributes[`${type}GraduationLevel`]}</h4>
                        <p class="paragraphDetail">{detail.attributes[`${type}Description`]}</p>
                    </>
                )}
            </div>

            {detail.attributes && (
                <InteractionArea interactionName={`${type}-pages/${detail.id}`} />
            )}
        </div>
    );
}

export default DetailView;
